<template>
  <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">
    <Status></Status>
    <div style="flex-grow: 1; display: flex; flex-direction: column; overflow: hidden">
      <div>
        <v-toolbar flat color="white" class="mt-2">
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>fa-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title @click="picker = true">{{ currentDate }}</v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>fa-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined small color="grey darken-2" @click="setToday">Today</v-btn>
        </v-toolbar>
      </div>
      <div style="flex-grow: 1; overflow: scroll">
        <v-calendar
          ref="calendar"
          v-model="start"
          color="primary"
          :type="type"
          hide-header
          :start="start"
          :categories="categories"
          :events="events"
          :event-color="getEventColor"
          @change="calChange"
        ></v-calendar>
      </div>
    </div>
    <v-dialog v-model="picker">
      <v-date-picker v-model="start" year-icon="fa-calendar-alt" prev-icon="fa-chevron-left" next-icon="fa-chevron-right">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="picker = false">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import { rrulestr } from "rrule";
export default {
  name: "Calendar",
  components: {
    Status
  },
  created() {},
  data: () => {
    let today = new Date();
    return {
      codes: [],
      nextCodeUpdate: 0,
      picker: false,
      start: today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + (today.getDate() - 1),
      type: "month", //options: day, month, week
      events: [],
      categories: ["John Smith"]
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    currentDate() {
      let cal = new Date(Date.parse(this.start) + 86400000);
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return cal.getDate() + " " + months[cal.getMonth()] + " " + cal.getFullYear();
    }
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    async calChange({ start, end }) {
      let eventSet = [];
      let data = this.codes;
      if (Date.now() > this.nextCodeUpdate) {
        this.nextCodeUpdate = Date.now() + 1000 * 60;
        data = await this.$store.db.codes.get(this.$store.device.id);
        this.codes = data;
      }
      for (let item of data.filter(item => item.has_schedule)) {
        // console.log(item);
        let info = rrulestr(item.rrule);

        info.options.dtstart = new Date(start.date);
        let events = info.between(new Date(start.date), new Date(end.date));
        for (let event of events) {
          eventSet.push({
            name: item.description,
            start: event,
            end: event,
            color: item.color,
            timed: item.all_day
          });
        }
      }
      this.events = eventSet;
    },
    setToday() {
      let today = new Date();
      this.start = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + (today.getDate() - 1);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async gen() {
      this.$store.db.codes.add({
        targetDeviceID: "2f1aa36f-057f-4354-8487-2b7a894be75c",
        targetUserID: "PNtK4aqVJjRFclJKarsML2w0rht1",
        rrule: "FREQ=DAILY;INTERVAL=7;UNTIL=20250904T060000Z",
        otp: "21654984",
        combo: "123456789", //combination code 9 digits or less. Otherwise digit 10 is 4 or less.
        has_schedule: true, //if calendar should be applied to the code
        all_day: false, // bool for all day
        time_start: new Date(Date.UTC(2012, 1, 1, 10, 30)),
        time_end: new Date(Date.UTC(2024, 12, 31)),
        series_start: new Date(Date.UTC(2012, 1, 1, 10, 30)),
        series_end: new Date(Date.UTC(2024, 12, 31)),
        color: "#4CAF50",
        description: "code"
      });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  }
};
</script>